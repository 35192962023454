<template>
  <div>
<!--    <head-layout head-title="待我填报"></head-layout>-->
    <div class="homeTopTabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待填报" name="first"></el-tab-pane>
        <el-tab-pane label="已填报" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      class="home"
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad">
      <template #customBtn="{row}">
        <el-button
          type="text"
          @click="fill(row)"
        > <span v-if="activeName=='first'">填报</span><span v-else>查看</span>
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList} from "@/api/reportReview";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          label: "报表名称",
          prop: "reportCode",
          type: "select",
          dicUrl: "/api/sinoma-report/acquisition/attributeFixation/authorityReport?type=fill",
          dicData: [],
          props: {
            label: "templateName",
            value: "templateCode",
          },
          span: 4,
          placeholder: "请选择报表名称",
        },
        {
          label: "填报组织",
          prop: "fillDeptId",
          type: "select",
          dicUrl: "/api/sinoma-report/environment/insideOrOutside/fillSummaryList?type=fill",
          dicData: [],
          props: {
            label: "deptName",
            value: "id",
          },
          span: 4,
          placeholder: "请选择填报组织",
        },
      ],
      tableData: [],
      activeName:"first",
      tableLoading: false,
      tableOption: {
        menu: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        linklabel: "reportName",
        column: [
          {
            label: "报表名称",
            prop: "reportName",
            align: "left",
            overHidden: true,
          },
          {
            label: "填报组织",
            prop: "fillDeptName",
            align: "left",
            overHidden: true,
          },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "通知填报日期",
            prop: "taskInDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "截至日期",
            prop: "deadlineFillingDate",
            align: "center",
            overHidden: true,
          },
          // {
          //   label: "待我填报数",
          //   prop: "fillTotal",
          //   align: "center",
          //   overHidden: true,
          // },
        ]
      }
    }
  },
  mounted() {
    this.onLoad(this.page, {});
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-report") {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem("sinoma-report", JSON.stringify(false)); //变成false , 可以来回监听
        //要做的操作
      }
    });
  },
  methods: {
    handleClick() {
      this.onLoad(this.page,{type:this.activeName});
    },
    fill(row) {
      window.open('/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=' + row.reportCode + '&statDate=' + row.statDate + '&fillDeptId=' + row.fillDeptId)
    },
    summary(row) {
      window.open('/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=' + row.reportCode + '&statDate=' + row.statDate + '&fillDeptId=' + row.fillDeptId + '&fillDeptName=' + row.fillDeptName)
    },
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // this.$loading()
      params.fillSummaryType = 'fill'
      params.type = this.activeName
      getList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.tableData = data.records
        this.page.total = data.total;
        this.$refs.gridLayOut.page.total = data.total
        this.tableLoading = false;
        // this.$loading().close()
        this.selectionList = [];
      }).catch(()=>{
        this.tableLoading = false;
        // this.$loading().close()
      }).finally(()=>{
        this.tableLoading = false;
        // this.$loading().close()
      });
    },
    rowView(row) {
      this.$emit('runApproval', row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.homeTopTabs {
  width: calc(100% - 24px);
  padding: 0 12px;
  background-color: #FFF;
}

.homeTopTabs ::v-deep .el-tabs__header {
  margin: 0;
}
</style>
